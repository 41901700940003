import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { Flex } from "theme-ui"

import Accordion from "../accordion"
import ArrowRight from "../../../assets/svg/arrow-left.svg"
import AssetWrapper from "../asset-wrapper"
import Button from "../button"
import { ContentfulTypes } from "../../../utils/types"
import Image from "../image"
import React from "react"
import handleReferenceLink from "../../../utils/functions/handleReferenceLink"
import { navigate } from "gatsby-link"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "@emotion/styled"
import loadable from "@loadable/component"

const RichTextTooltip = loadable(() => import("../tooltip/rich-text-tooltip"), {
  ssr: false,
})

const RichTextContainer = styled(Flex)`
  padding: ${(props) => props?.sx?.padding ?? "1rem 0rem"};
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  text-align: ${(props) =>
    props.isCenter || props.centerText ? "center" : "left"};

  ${(props) => props.margin === "Small" && "margin: 0 5%;"}
  ${(props) => props.margin === "Medium" && "margin: 0 20%;"}
  ${(props) => props.margin === "Large" && "margin: 0 40%;"}

  svg {
    width: 20px;

    &.rotate-180 {
      transform: rotate(180deg);
    }
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 16px;
  }

  p {
    font-size: 13px;
    margin-bottom: ${(props) => props?.sx?.p?.marginBottom ?? "1em"};
  }

  span.not(.tooltip) {
    font-size: 16px;
  }

  ul {
    margin-left: 0px;
    list-style: none;

    p {
      margin-bottom: 0px;
    }
  }

  li {
    margin-bottom: 0px;
  }

  a {
    color: inherit;
  }

  b {
    font-weight: bold;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: normal;
    margin: 0.6em 0;
    line-height: 1.2;
  }

  img {
    object-fit: cover;
    object-position: center;

    &.img-half {
      object-fit: contain;
      height: 500px;
      width: auto;
    }
  }

  *:last-child {
    margin-bottom: 0;
  }

  .accordion-holiday-bundle & {
    p {
      font-size: 13px;
    }
  }
`

const options = {
  renderMark: {
    [MARKS.CODE]: (code) => handleCode(code),
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (props) => {
      const { data } = props
      if (!data?.target) {
        return null
      }

      if (data.target.__typename === ContentfulTypes.ContentfulButton) {
        return (
          <Button
            onClick={() =>
              navigate(
                handleReferenceLink({
                  reference: data.target.reference,
                  locale: "en-US",
                })
              )
            }
            variant={data.target.type}
          >
            {data.target.label}
          </Button>
        )
      }
      if (data.target.__typename === ContentfulTypes.ContentfulAccordion) {
        return (
          <Accordion
            items={data.target.items}
            sx={{ paddingY: "1em" }}
            itemSx={{ marginBottom: "1em" }}
            {...data.target}
          />
        )
      }
      if (data.target.__typename === ContentfulTypes.ContentfulAssetWrapper) {
        return <AssetWrapper asset={data.target.asset} {...data.target} />
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: function createImage(node) {
      return (
        <Image
          className="img-full"
          image={node.data.target.gatsbyImageData}
          alt="contentful-image"
        />
      )
    },
    [BLOCKS.PARAGRAPH]: (node, children) =>
      children != "" ? <p>{children}</p> : "",
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      if (node?.data?.target?.internal?.type !== "ContentfulTooltip") {
        return children
      }

      return <RichTextTooltip node={node}>{children}</RichTextTooltip>
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const options = {
        href: node.data.uri,
      }

      if (node?.data?.uri?.startsWith("https://assets.ctfassets.net")) {
        options.target = "_blank"
      }

      return <a {...options}>{children}</a>
    },
  },
}

const handleCode = (code) => {
  switch (code) {
    case "->":
      return <ArrowRight className="rotate-180" />
  }
}

const RichText = ({ centeredText, input, sx, ...rest }) => {
  const { body } = input
  const { centerText, margin } = rest
  return (
    <RichTextContainer
      isCenter={centeredText}
      sx={sx}
      className="rich-text"
      centerText={centerText}
      margin={margin}
    >
      {body ? renderRichText(body, options) : ""}
    </RichTextContainer>
  )
}

export default RichText
