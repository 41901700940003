import React, { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"

import { Flex, Text, Box } from "theme-ui"

import Play from "../../../assets/svg/play.svg"
import Pause from "../../../assets/svg/pause.svg"

export const VideoContainer = styled.video`
  position: relative;
  height: 100%;
  width: 100%;
`

const Wrapper = styled(Box)`
  height: 100%;
  width: 100%;
  position: relative;
  aspect-ratio: ${(props) => props.aspectRatio ?? "1.5"};
`

const ControlsContainer = styled(Flex)`
  position: absolute;
  justify-content: space-between;
  align-items: center;
  z-index: 20;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
`

const IconContainer = styled(Flex)`
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`

const Video = ({ src, key, ...rest }) => {
  const videoRef = useRef()
  const observer = useRef()
  const [currentTime, setCurrentTime] = useState(0)
  const [currentVolume, setCurrentVolume] = useState(0)
  const [isPaused, setIsPaused] = useState(true)

  const videoIntersectingCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        videoRef.current?.play()
        setIsPaused(false)
      }
      if (!entry.isIntersecting) {
        videoRef.current?.pause()
        setIsPaused(true)
      }
    })
  }

  useEffect(() => {
    observer.current = new IntersectionObserver(videoIntersectingCallback, {
      threshold: 0.8,
    })

    if (videoRef.current && observer) {
      observer.current.observe(videoRef.current)
      videoRef.current.volume = 0
      setCurrentVolume(0)
    }
  }, [videoRef, observer])

  const handlePlay = () => {
    const video = videoRef.current

    if (video.paused) {
      video.play()
      setIsPaused(false)
    } else {
      video.pause()
      setIsPaused(true)
    }
  }

  const handleMute = () => {
    const video = videoRef.current

    if (video.volume === 0) {
      video.volume = 1
    } else {
      video.volume = 0
    }
  }

  const handleTimeUpdate = () => {
    const time = videoRef?.current?.currentTime?.toFixed(0)
    if (time !== currentTime) {
      setCurrentTime(time)
    }
  }

  const handleVolumeChange = () => {
    const volume = videoRef?.current?.volume
    if (volume !== currentVolume) {
      setCurrentVolume(volume)
    }
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = time - minutes * 60
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`
  }

  return (
    <Wrapper className="video-player" id="player" key={key} {...rest}>
      <VideoContainer
        src={src}
        ref={videoRef}
        loop={true}
        onTimeUpdate={handleTimeUpdate}
        onVolumeChange={handleVolumeChange}
        controlsList="nodownload nofullscreen"
      ></VideoContainer>
      <ControlsContainer>
        <Flex sx={{ alignItems: "center" }}>
          <IconContainer
            sx={{
              alignItems: "center",
              paddingRight: "2em",
            }}
          >
            {isPaused ? (
              <Play onClick={handlePlay} />
            ) : (
              <Pause onClick={handlePlay} />
            )}
          </IconContainer>

          <Text sx={{ fontSize: "13px" }}>{formatTime(currentTime)}</Text>
        </Flex>
        <Flex>
          <Text onClick={handleMute} sx={{ cursor: "pointer" }}>
            {currentVolume === 1 ? "Sound Off" : "Sound On"}
          </Text>
        </Flex>
      </ControlsContainer>
    </Wrapper>
  )
}

export default Video
