import React from "react"

import NotificationContext, {
  defaultNotificationContext,
} from "../../context/NotificationContext"

class NotificationProvider extends React.Component {
  state = {
    ...defaultNotificationContext,
    show: {},
    dismissNotification: id => {
      this.setState(state => {
        const newNotifications = state.notifications
        const index = newNotifications.findIndex(n => n.id === id)
        if (index !== -1) {
          if (newNotifications[index].onDismiss) {
            newNotifications[index].onDismiss(this.state)
          }
          newNotifications.splice(index, 1)
        }

        return {
          notifications: newNotifications,
        }
      })
    },
    showNotification: id => {
      this.setState(state => ({
        show: {
          ...state.show,
          [id]: true,
        },
      }))
    },
    pushNotification: notification => {
      const delay = notification.delay || 0
      setTimeout(() => {
        this.setState(state => {
          const newNotifications = state.notifications.concat([notification])

          if (notification.dismiss && notification.dismiss.duration) {
            setTimeout(() => {
              state.dismissNotification(notification.id)
            }, notification.dismiss.duration)
          }

          setTimeout(() => {
            state.showNotification(notification.id)
          }, 100)

          return {
            notifications: newNotifications,
            show: {
              ...state.show,
              [notification.id]: false,
            },
          }
        })
      }, delay)
    },
  }

  componentDidMount() {
    if (this.props.notifications) {
      this.props.notifications.forEach(n => {
        this.state.pushNotification(n)
      })
    }

    if (this.props.initializer) {
      this.props.initializer(this.state).forEach(n => {
        this.state.pushNotification(n)
      })
    }
  }

  render() {
    return (
      <NotificationContext.Provider value={this.state}>
        {this.props.children}
      </NotificationContext.Provider>
    )
  }
}

export default NotificationProvider
