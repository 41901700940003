import React from "react"
import styled from "@emotion/styled"
import { Flex, Box } from "theme-ui"

import { handleMediaRender } from "../../../utils/render-util"
import handleReferenceLink from "../../../utils/functions/handleReferenceLink"
import Link from "../../../utils/Link"

import IconArrowRight from "../../../assets/icons/arrow-right.svg"
import IconArrowRightWhite from "../../../assets/icons/arrow-right-white.svg"

const AssetContainer = styled(Flex)`
  flex-direction: ${(props) => props.sx?.flexDirection ?? "row"};
  flex: ${(props) => props?.sx?.flex ?? "1"};
  cursor: ${(props) => props.withReference && "pointer"};
  text-decoration: none;

  .previous {
    transform: rotate(180deg);
  }
`

const ImageText = styled(Box)`
  ${(props) =>
    props.navigationAssetWrapper &&
    `
      font-size: 13px;
      margin-top: 8px;
    `}

  ${(props) =>
    props.placementOnImage &&
    `
      position: absolute;
      bottom: 16px;
      left: 16px;

      &::after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        left: calc(100% + 6px);
        transform: translateY(-50%);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform 0.2s;
      }

      a:hover & {
        &::after {
          transform: translateY(-50%) translateX(4px);
        }
      }
    `}
`

const AssetWrapper = ({
  asset,
  className,
  id,
  index,
  onClick,
  navigationAssetWrapper = false,
  assetTitle = "",
  ...rest
}) => {
  const { imageText, hasDarkBackground, textPlacement, reference, linkTo } =
    rest
  let assetLink =
    linkTo?.indexOf("https") === 0
      ? linkTo
      : handleReferenceLink({
          reference: reference,
          locale: "en-US",
          linkUrl: linkTo,
        })

  const handleAssetWrapperClicked = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <AssetContainer
      className={className}
      id={id}
      index={index}
      withReference={assetLink}
      onClick={handleAssetWrapperClicked}
      as={Link}
      to={assetLink}
      target={assetLink?.indexOf("https") === 0 ? "_blank" : ""}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        {handleMediaRender(asset, rest, assetTitle)}
        {imageText && (
          <ImageText
            placementOnImage={textPlacement === PlacementOptions.ON_IMAGE}
            navigationAssetWrapper={navigationAssetWrapper}
            className="asset-image-text"
            sx={{
              color: hasDarkBackground ? "grayscale.white" : "grayscale.700",

              "&::after": {
                backgroundImage: hasDarkBackground
                  ? `url("${IconArrowRightWhite}")`
                  : `url("${IconArrowRight}")`,
              },
            }}
          >
            {imageText}
          </ImageText>
        )}
      </Box>
    </AssetContainer>
  )
}

const PlacementOptions = {
  ON_IMAGE: "On Image",
  UNDER: "Under",
}

export default AssetWrapper
