import { useEffect, useState } from "react"

const useDisableBackgroundScroll = (initialScrollable) => {
  const [scrollable, setScrollable] = useState(initialScrollable || true)

  useEffect(() => {
    const [htmlTag] = document.getElementsByTagName("html")

    if (scrollable) {
      htmlTag.style.removeProperty("overflow")
    } else {
      htmlTag.style.overflow = "hidden"
    }
  }, [scrollable])

  return [scrollable, setScrollable]
}

export default useDisableBackgroundScroll
