import React, { useEffect, useReducer } from "react"

const defaultContext = {
  gender: "female",
  setGender: () => {},
  changeGender: () => {},
}

export const PersonalizationContext = React.createContext(defaultContext)

const reducer = (state, action) => {
  switch (action.type) {
    case "setGender":
      return {
        ...state,
        gender: action.payload,
      }
  }
}

export const PersonalizationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultContext)

  const setGender = gender => {
    if (gender === "female" || gender === "male") {
      dispatch({ type: "setGender", payload: gender })
    }
  }

  const changeGender = () => {
    if (state.gender === "male") {
      localStorage.setItem("tekla::pg", "f")
      // if (window && window.analytics) {
      //   window.analytics.identify({
      //     gender: "female",
      //   })
      // }
      setGender("female")
    } else {
      localStorage.setItem("tekla::pg", "m")
      // if (window && window.analytics) {
      //   window.analytics.identify({
      //     gender: "male",
      //   })
      // }
      setGender("male")
    }
  }

  useEffect(() => {
    const gender = localStorage.getItem("tekla::pg")
    if (gender) {
      setGender(gender === "m" ? "male" : "female")
    }
  }, [])

  return (
    <PersonalizationContext.Provider
      value={{
        ...state,
        setGender,
        changeGender,
      }}
    >
      {children}
    </PersonalizationContext.Provider>
  )
}
