import styled from "@emotion/styled"
import React from "react"
import { Button as RebassButton } from "theme-ui"

const StyledButton = styled(RebassButton)`
  ${(props) =>
    (props.disabled || props.isDisabled) &&
    `
      cursor: default;
      background-color: ${props.theme.colors.grayscale[600]};
      border-color: ${props.theme.colors.grayscale[600]};
      color: ${props.theme.colors.grayscale[100]};

      :hover {
        background-color: ${props.theme.colors.grayscale[600]};
        border-color: ${props.theme.colors.grayscale[600]};
        color: ${props.theme.colors.grayscale[100]};
      }
    `}
`

const VariantStyledButton = styled(RebassButton)`
  display: flex;
  width: 50%;
  padding: 12px;
  justify-content: center;
  background-color: #e6e4e3;

  :hover {
    background-color: ${(props) => props.theme.colors.grayscale.white};

    .button-text {
      opacity: 1;
    }
  }

  ${(props) =>
    (props.disabled || props.isDisabled) &&
    `
      pointer-events: none;

      .button-text {
        opacity: 1;
        color: ${props.theme.colors.grayscale[100]};
      }
    `}
`

const Button = ({
  children,
  variant = "primary",
  onClick,
  disabled,
  icon,
  className,
  sx,
  ...rest
}) => {
  return (
    <StyledButton
      onClick={onClick}
      variant={`buttons.${variant}`}
      disabled={disabled}
      className={className}
      sx={sx}
      {...rest}
    >
      {children}
      {icon && icon}
    </StyledButton>
  )
}

export default Button

export const VariantButton = ({
  children,
  onClick,
  disabled,
  icon,
  className,
  sx,
  ...rest
}) => {
  return (
    <VariantStyledButton
      onClick={onClick}
      disabled={disabled}
      className={className}
      sx={sx}
      {...rest}
    >
      {children}
      {icon && icon}
    </VariantStyledButton>
  )
}

/*
  This button doesn't follow the usual disabled state,
  but instead fires a specific disabledOnClick event
*/
export const ButtonDisabledOnClick = ({
  variant = "primary",
  children,
  onClick,
  isDisabled,
  disabledOnClick,
  icon,
  className,
  sx,
  ...rest
}) => {
  const handleClick = () => {
    if (isDisabled) {
      disabledOnClick()
    } else {
      onClick()
    }
  }

  return (
    <StyledButton
      onClick={handleClick}
      variant={`buttons.${variant}`}
      sx={sx}
      isDisabled={isDisabled}
      className={className}
      {...rest}
    >
      {children}
      {icon && icon}
    </StyledButton>
  )
}