import { borders } from "./borders"
import { colors } from "./colors"

const base = {
  borderRadius: 0,
  fontSize: "15px",
  border: 0,
  outline: 0,
  cursor: "pointer",
}

export const buttons = {
  primary: {
    ...base,
    color: colors.grayscale[100],
    backgroundColor: colors.grayscale[700],
    ":hover": {
      backgroundColor: colors.grayscale[600],
    },
    ":disabled": {
      backgroundColor: colors.grayscale[600],
    },
    ":active": {
      backgroundColor: colors.grayscale[700],
    },
  },
  secondary: {
    ...base,
    color: colors.grayscale[100],
    backgroundColor: colors.green,
  },
  danger: {
    ...base,
    color: colors.grayscale[100],
    backgroundColor: colors.red,
  },
  darkest: {
    ...base,
    color: colors.grayscale[100],
    backgroundColor: colors.primary,
  },
  light: {
    ...base,
    color: colors.grayscale[700],
    backgroundColor: colors.grayscale.white,
    border: borders.dark,
  },
  base: {
    ...base,
    color: colors.grayscale[700],
    backgroundColor: colors.grayscale[100],
    border: borders.dark,
  },
  transparent: {
    ...base,
    color: colors.grayscale[700],
    backgroundColor: "transparent",
    border: borders.dark,
  },
}
