export const borders = {
  muted: "1px solid #4545451A",
  blackTransparent: `1px solid #0000001a`,
  black: "1px solid black",
  grey: "1px solid #E9E9E9",
  dark: "1px solid #454545",
  brownGrey: "1px solid rgba(159, 159, 159, 0.4)",
  danger: "1px solid #EB0000",
  transparent: "1px solid transparent",
}
