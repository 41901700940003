export const colors = {
  primary: "#181818",
  red: "#ce4646",
  green: "#4c9a62",
  grayscale: {
    white: "#ffffff",
    100: "#f6f6f6",
    200: "#ececec",
    300: "#e3e3e3",
    400: "#c4c4c4",
    500: "#9e9e9d",
    600: "#707070",
    700: "#454545",
  },
}
