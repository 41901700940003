import React, { useReducer, useEffect, useContext } from "react"

export const defaultInterfaceContext = {
  scrollPos: 0,
  prevScrollPos: 0,
  isCartOpen: false,
  isProductNavOpen: false,
  isLoginOpen: false,
  isRegionOpen: false,
  isSearchOpen: false,
  modalVisible: false,
  modalContent: null,
  header: "",
  wishlistCount: 0,
  isHeaderHidden: false,
  isPromotionalVisible: true,
  setHeader: () => {},
  setModalContent: () => {},
  setScrollPos: () => {},
  setRegion: () => {},
  hideDrawers: () => {},
  showLogin: () => {},
  showProductNav: () => {},
  showCart: () => {},
  showRegion: () => {},
  setModalVisible: () => {},
  toggleModal: () => {},
  toggleCart: () => {},
  toggleProductNav: () => {},
  toggleRegionOpen: () => {},
  toggleSearchOpen: () => {},
  setWishlistCount: () => {},
  setHeaderHidden: () => {},
  setIsPromotionalVisible: () => {},
}

export const InterfaceContext = React.createContext(defaultInterfaceContext)

export default React.createContext(defaultInterfaceContext)

export const useInterfaceContext = () => useContext(InterfaceContext)

const reducer = (state, action) => {
  switch (action.type) {
    case "setModalContent":
      return {
        ...state,
        modalContent: action.payload,
      }

    case "setScrollPos":
      return {
        ...state,
        prevScrollPos: state.scrollPos,
        scrollPos: action.payload,
      }

    case "hideDrawers": {
      // const [htmlTag] = document.getElementsByTagName("html")
      // const [bodyTag] = document.getElementsByTagName("body")

      // htmlTag.style.removeProperty("overflow")
      // bodyTag.style.removeProperty("overflow")

      return {
        ...state,
        isLoginOpen: false,
        isCartOpen: false,
        isProductNavOpen: false,
        isRegionOpen: false,
        isSearchOpen: false,
        modalVisible: false,
      }
    }

    case "setHeader":
      return {
        ...state,
        header: action.payload,
      }

    case "showLogin":
      return {
        ...state,
        isLoginOpen: true,
        isProductNavOpen: false,
        isCartOpen: false,
        isSearchOpen: false,
        isRegionOpen: false,
      }

    case "showProductNav":
      return {
        ...state,
        isLoginOpen: false,
        isProductNavOpen: true,
        isCartOpen: false,
        isSearchOpen: false,
        isRegionOpen: false,
      }

    case "showCart": {
      return {
        ...state,
        isCartOpen: true,
        isProductNavOpen: false,
        isSearchOpen: false,
        isLoginOpen: false,
        isRegionOpen: false,
      }
    }

    case "showRegion":
      return {
        ...state,
        isCartOpen: false,
        isLoginOpen: false,
        isSearchOpen: false,
        isRegionOpen: true,
      }

    case "toggleProductNav":
      return {
        ...state,
        isLoginOpen: false,
        isCartOpen: false,
        isSearchOpen: false,
        isRegionOpen: false,
        isProductNavOpen: !state.isProductNavOpen,
      }

    case "toggleModal":
      return {
        ...state,
        modalVisible: !state.modalVisible,
      }

    case "setModalVisible":
      return {
        ...state,
        modalVisible: action.payload,
      }

    case "toggleRegionOpen":
      return {
        ...state,
        isCartOpen: false,
        isLoginOpen: false,
        isSearchOpen: false,
        isRegionOpen: !state.isRegionOpen,
      }

    case "toggleSearchOpen":
      return {
        ...state,
        isCartOpen: false,
        isProductNavOpen: false,
        isLoginOpen: false,
        isSearchOpen: !state.isSearchOpen,
      }

    case "toggleCart": {
      return {
        ...state,
        isCartOpen: !state.isCartOpen,
        isProductNavOpen: false,
        isLoginOpen: false,
        isRegionOpen: false,
      }
    }

    case "setWishlistCount": {
      return {
        ...state,
        wishlistCount: action.payload,
      }
    }

    case "setHeaderHidden": {
      return {
        ...state,
        isHeaderHidden: action.payload,
      }
    }

    case "setIsPromotionalVisible": {
      return {
        ...state,
        isPromotionalVisible: action.payload,
      }
    }
  }
}

export const InterfaceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultInterfaceContext)

  const setModalContent = (content) => {
    if (content) {
      dispatch({ type: "setModalContent", payload: content })
    }
  }

  const setScrollPos = (position) => {
    if (position != null) {
      dispatch({ type: "setScrollPos", payload: position })
    }
  }

  const hideDrawers = () => {
    dispatch({ type: "hideDrawers" })
  }

  const setHeader = (header) => {
    if (header) {
      dispatch({ type: "setHeader", payload: header })
    }
  }

  const showLogin = () => {
    dispatch({ type: "showLogin" })
  }

  const showProductNav = () => {
    dispatch({ type: "showProductNav" })
  }

  const showCart = () => {
    dispatch({ type: "showCart" })
  }

  const showRegion = () => {
    dispatch({ type: "showRegion" })
  }

  const toggleProductNav = () => {
    dispatch({ type: "toggleProductNav" })
  }

  const toggleModal = () => {
    dispatch({ type: "toggleModal" })
  }

  const setModalVisible = (visible) => {
    dispatch({ type: "setModalVisible", payload: visible })
  }

  const toggleRegionOpen = () => {
    dispatch({ type: "toggleRegionOpen" })
  }

  const toggleCart = () => {
    dispatch({ type: "toggleCart" })
  }

  const toggleSearchOpen = () => {
    dispatch({ type: "toggleSearchOpen" })
  }

  const setWishlistCount = (count) => {
    dispatch({ type: "setWishlistCount", payload: count })
  }

  const setHeaderHidden = (hidden) => {
    dispatch({ type: "setHeaderHidden", payload: hidden })
  }

  const setIsPromotionalVisible = (visible) => {
    dispatch({ type: "setIsPromotionalVisible", payload: visible })
  }

  useEffect(() => {
    const wishlist = JSON.parse(localStorage.getItem("medusa::cache::wishlist"))

    if (wishlist && wishlist.length) {
      setWishlistCount(wishlist.length)
    }

    return () => {
      window.removeEventListener("scroll", () => {})
    }
  }, [])

  return (
    <InterfaceContext.Provider
      value={{
        ...state,
        setModalContent,
        setScrollPos,
        hideDrawers,
        setHeader,
        showLogin,
        showProductNav,
        showCart,
        showRegion,
        toggleProductNav,
        toggleModal,
        setModalVisible,
        toggleRegionOpen,
        toggleCart,
        toggleSearchOpen,
        setWishlistCount,
        setHeaderHidden,
        setIsPromotionalVisible,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  )
}
