import { css } from "@emotion/react"

const Spacing = (props) => css`
  ${props.width && `width: calc(${props.width}/12 * 100%);`}
  ${props.wrap && "flex-wrap: wrap;"}
  ${props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props.ml && `margin-left: ${props.ml};`}
  ${props.mr && `margin-right: ${props.mr};`}

  ${props.p && `padding: ${props.p};`}

  ${props.pt && `padding-top: ${props.pt};`}
  ${props.pb && `padding-bottom: ${props.pb};`}
  ${props.px &&
  `
    padding-left: ${props.px};
    padding-right: ${props.px};
  `}
  ${props.py &&
  `
    padding-top: ${props.py};
    padding-bottom: ${props.py};
  `}
`

export default Spacing
