const ContentfulProduct = "ContentfulProduct"
const ContentfulProductList = "ContentfulProductList"
const ContentfulBeddingBundle = "ContentfulBeddingBundle"
const ContentfulCollection = "ContentfulCollection"
const ContentfulCategory = "ContentfulCategory"
const ContentfulAccordion = "ContentfulAccordion"
const ContentfulRichText = "ContentfulRichTextOld"
const ContentfulBlogContent = "ContentfulBlogContentOld"
const ContentfulBlogPost = "ContentfulBlogPost"
const ContentfulTileSection = "ContentfulTileSectionOld"
const ContentfulTile = "ContentfulTileOld"
const ContentfulNavigationItem = "ContentfulNavigationItem"
const ContentfulPage = "ContentfulPage"
const ContentfulGlobalSizeGuide = "ContentfulGlobalSizeGuide"
const ContentfulButton = "ContentfulButton"
const ContentfulAssetWrapper = "ContentfulAssetWrapperOld"
const ContentfulNavigationMenu = "ContentfulNavigationMenu"
const ContentfulShopstoryContent = "ContentfulShopstoryContent"

export const ContentfulTypes = {
  ContentfulProduct: ContentfulProduct,
  ContentfulProductList: ContentfulProductList,
  ContentfulBeddingBundle: ContentfulBeddingBundle,
  ContentfulCollection: ContentfulCollection,
  ContentfulCategory: ContentfulCategory,
  ContentfulAccordion: ContentfulAccordion,
  ContentfulRichText: ContentfulRichText,
  ContentfulBlogContent: ContentfulBlogContent,
  ContentfulBlogPost: ContentfulBlogPost,
  ContentfulTileSection: ContentfulTileSection,
  ContentfulTile: ContentfulTile,
  ContentfulNavigationItem: ContentfulNavigationItem,
  ContentfulPage: ContentfulPage,
  ContentfulGlobalSizeGuide: ContentfulGlobalSizeGuide,
  ContentfulButton: ContentfulButton,
  ContentfulAssetWrapper: ContentfulAssetWrapper,
  ContentfulNavigationMenu,
  ContentfulShopstoryContent,
  ContentfulShopTheLook: "ContentfulShopTheLook",
  ContentfulGrid: "ContentfulGrid",
  ContentfulHero: "ContentfulHero",
  ContentfulImageGallery: "ContentfulImageGallery",
  ContentfulTileV2: "ContentfulTileV2",
  ContentfulImage: "ContentfulImage",
  ContentfulDrawer: "ContentfulDrawer",
  ContentfulWidgetDrawer: "ContentfulWidgetDrawer",
  ContentfulWidgetLink: "ContentfulWidgetLink",
  ContentfulWidgetShopTheLook: "ContentfulWidgetShopTheLook",
}

export const MediaType = {
  JPG: "image/jpeg",
  PNG: "image/png",
  WEBP: "image/webp",
  GIF: "image/gif",
  MP4: "video/mp4",
  TEXT: "text/html",
  QUICKTIME: "video/quicktime",
}
