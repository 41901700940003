import React, { useState, useCallback, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { useSwipeable } from "react-swipeable"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Flex, Text } from "theme-ui"

import Arrow from "../../../assets/svg/signifly-arrow.svg"
import ArrowRightUrl from "../../../assets/icons/signifly-arrow-right.svg"
import ArrowLeftUrl from "../../../assets/icons/signifly-arrow-left.svg"
import { useDrag, usePinch } from "react-use-gesture"
import CloseIcon from "../../../assets/svg/dismiss.svg"
import useDisableBackgroundScroll from "../../../hooks/useDisableBackgroundScroll"
import useMouseCoordinatesInElement from "../../../hooks/useMouseCoordinatesInElement"

const ImageContainer = styled(Flex)`
  position: ${(props) => props.sx?.position || "relative"};
  width: ${(props) => props.sx?.width || "100%"};
  cursor: ${(props) => (props.withClick ? "pointer" : "inherit")};
  color: ${(props) => props.theme.colors.grayscale[700]};
  height: inherit;
  flex: 1;

  ${(props) =>
    props.hasDarkBackground &&
    `
      color: ${props.theme.colors.grayscale.white};
      svg {
        path {
          stroke: ${props.theme.colors.grayscale.white};

        }
      }
    `}

  img {
    width: ${(props) => props.sx?.img?.width ?? "100%"};
    height: ${(props) => props.sx?.img?.height ?? "100%"};
    object-fit: ${(props) => props.sx?.img?.objectFit ?? "cover"};
  }

  ${(props) =>
    props.isZoomed &&
    `
    transform: scale(1.5);
    cursor: zoom-out;
  `}

  ${(props) =>
    props.withZoom &&
    `
    cursor: zoom-in;
  `}
`

const Image = ({
  src,
  image,
  alt,
  onImageClick,
  mobileWindowOpen,
  setMobileWindowOpen,
  withZoom,
  position,
  sx,
  ...rest
}) => {
  const [isZoom, setIsZoom] = useState(false)
  const [coords, setCoords, getDelta] = useMouseCoordinatesInElement()
  const [, setScrollable] = useDisableBackgroundScroll()
  let ref = useRef()

  let ZoomStyles = {
    left: (isZoom && coords.deltaX) || 0,
    top: (isZoom && coords.deltaY) || 0,
  }

  const handleClick = (e) => {
    if (mobileWindowOpen) {
      return
    }

    if (withZoom) {
      if (window.innerWidth <= 768) {
        handleMobileClick(e)
        return
      }
      setCoords({ ...coords, x: e.clientX, y: e.clientY })
      setIsZoom(!isZoom)
    } else if (onImageClick) {
      onImageClick()
    }
  }

  const handleMobileClick = () => {
    setMobileWindowOpen(true)
    setScrollable(false)
  }

  const handleZoom = (e) => {
    if (withZoom && isZoom) {
      getDelta(e)
    }
  }

  return (
    <ImageContainer
      withClick={!!onImageClick}
      withZoom={withZoom}
      isZoomed={isZoom}
      sx={sx}
      ref={ref}
      onMouseMove={(e) => handleZoom(e)}
      onClick={(e) => handleClick(e)}
      style={ZoomStyles}
      {...rest}
    >
      {mobileWindowOpen ? (
        <ImageZoom
          image={image}
          onClick={() => {
            setMobileWindowOpen(false)
            setScrollable(true)
          }}
        />
      ) : src ? (
        <img src={src} alt={alt} className="image" onClick={onImageClick} />
      ) : (
        <GatsbyImage
          image={image}
          style={{
            position: position,
            height: "100%",
            width: "100%",
            ...sx?.imageWrapper,
          }}
          imgStyle={{ ...sx?.img }}
          className="image"
          alt={alt || ""}
        />
      )}
    </ImageContainer>
  )
}

export default Image

const ImageZoom = ({ image, onClick, visible, alt }) => {
  const [scale, setScale] = useState(1)
  const [coords, setCoords] = useState({ x: 0, y: 0 })
  const [lastClick, setLastClick] = useState()

  useEffect(() => {
    setScale(1)
    setCoords({ x: 0, y: 0 })
  }, [visible])

  const dragBind = useDrag(({ event, delta: [d1, d2] }) => {
    const rect = event.target.getBoundingClientRect()
    let parentElement = document.getElementById("drag-element")
    parentElement.style.transition = "none"

    const prevD1 = coords.x
    const prevD2 = coords.y

    let newX = prevD1 + d1
    let newY = prevD2 + d2

    let rectX = rect.x
    let rectY = rect.y
    const width = window.innerWidth
    const height = window.innerHeight

    let xAxisBoundsOut = rectX > 0 || Math.abs(rectX) + width > rect.width
    let yAxisBoundsOut = rectY > 0 || Math.abs(rectY) + height > rect.height

    const cordLeftTopX = (rect.width - rect.width / scale) / 2
    const cordLeftTopY = (rect.height - rect.height / scale) / 2

    const cordRightBottomX = -(rect.width - rect.width / scale) / 2
    const cordRightBottomY = -(rect.height - rect.height / scale) / 2

    if (event.type === "pointerup") {
      if (yAxisBoundsOut || xAxisBoundsOut) {
        parentElement.style.transition = "left 400ms, top 400ms"
      }
      if (yAxisBoundsOut) {
        if (newY > 0) {
          newY = cordLeftTopY
        } else {
          newY = cordRightBottomY
        }
      }

      if (xAxisBoundsOut) {
        if (newX > 0) {
          newX = cordLeftTopX
        } else {
          newX = cordRightBottomX
        }
      }
    }

    setCoords({ x: newX, y: newY })
  })

  const pinchBind = usePinch(
    ({ offset: [d] }) => {
      const zoomFactor = 1 + d / 200
      if (zoomFactor < 1) {
        setScale(1)
        setCoords({ x: 0, y: 0 })
      } else {
        setScale(1 + d / 100)
      }
    },
    {
      eventOptions: {
        passive: false,
      },
    }
  )

  return (
    <Flex
      sx={{
        position: "fixed",
        justifyContent: "center",
        alignItems: "center",
        top: 0,
        zIndex: 9000,
        background: "grayscale.white",
      }}
    >
      <Flex
        sx={{
          position: "relative",
          flexDirection: "column",
          overflow: "hidden",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Flex
          mt="10px"
          mr="10px"
          sx={{
            position: "fixed",
            top: 0,
            right: 0,
            zIndex: 10,
            alignItems: "center",
            fontSize: "15px",
            cursor: "pointer",
            svg: {
              width: "8px",
              marginRight: "8px",
            },
          }}
          onClick={onClick}
        >
          <CloseIcon />
          Close
        </Flex>
        <Box
          sx={{ position: "relative" }}
          id="drag-element"
          style={{
            transform: `scale(${scale})`,
            left: coords.x || 0,
            top: coords.y || 0,
            touchAction: "none",
          }}
          onClick={(e) => {
            if (!lastClick) {
              setLastClick(e)
              return
            }

            let time1 = lastClick.timeStamp
            let time2 = e.timeStamp

            if (time2 - time1 < 500) {
              if (scale !== 1) {
                setScale(1)
                setCoords({ x: 0, y: 0 })
              } else {
                setScale(1.5)
              }
            }
            setLastClick(null)
          }}
          {...dragBind()}
          {...pinchBind()}
        >
          <GatsbyImage
            image={image}
            alt={alt || ""}
            imgStyle={{ objectFit: "scale-down" }}
            style={{
              height: "100vh",
            }}
          />
        </Box>
        <Flex
          sx={{
            color: "primary",
            fontSize: "14px",
            width: "100%",
            margin: "0 auto",
            flexDirection: "column",
            position: "fixed",
            alignItems: "center",
            bottom: 0,
            marginBottom: "44px",
          }}
        >
          <Text>Move your finger around to view</Text>
          <Text>Double tap to zoom</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

const CarouselContainer = styled(Flex)`
  position: relative;

  overflow: hidden;
  height: ${(props) => (props.sx?.height ? props.sx.height : "100%")};

  .previous,
  .next {
    margin: 0 5px;
    cursor: pointer;
  }

  .previous {
    transform: rotate(180deg);
  }

  .prod-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
    display: none;
    &:nth-of-type(${(props) => props.imgIndex}) {
      display: block;
    }
  }

  svg {
    path {
      stroke: ${(props) => props.theme.colors.grayscale[700]};
    }
  }

  ${(props) =>
    props.hasDarkBackground &&
    `
      color: ${props.theme.colors.grayscale.white};

      svg {
        path {
          stroke: ${props.theme.colors.grayscale.white};
        }
      }
    `}

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    button {
      width: unset;
    }
  }
`

const CarouselButton = styled.div`
  cursor: ${(props) =>
      props.next
        ? `url("${ArrowRightUrl}") 17 7`
        : `url("${ArrowLeftUrl}") 0 7`},
    pointer;
  position: absolute;

  font-size: 1rem;
  width: 30%;
  height: 100%;
  top: 0;
  z-index: 10;

  color: ${(props) => props.theme.colors.grayscale[700]};

  ${(props) =>
    props.next &&
    `
    right: 0;
    `}
`

export const ImageCarousel = ({
  images = [],
  hasHoverColor,
  hoverImage,
  sx,
  withZoom,
  bundleImagePackOptions,
  ...rest
}) => {
  const offset = 2

  const [imgIndex, setIndex] = useState(0)
  const [mobileWindowOpen, setMobileWindowOpen] = useState(false)

  const textRender = images?.[imgIndex]?.textRender

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => !mobileWindowOpen && handlePrevious(),
    onSwipedLeft: () => !mobileWindowOpen && handleNext(),
  })

  const handleNext = useCallback(() => {
    setIndex((imgIndex) => (imgIndex + 1) % images.length)
  }, [images.length])

  const handlePrevious = useCallback(() => {
    setIndex((imgIndex) => (imgIndex + images.length - 1) % images.length)
  }, [images.length])

  const handleKeyPress = useCallback(
    (e) => {
      if (e.code === "ArrowRight") {
        handleNext()
      }
      if (e.code === "ArrowLeft") {
        handlePrevious()
      }

      return
    },
    [handleNext, handlePrevious]
  )

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress)
    return () => document.removeEventListener("keydown", handleKeyPress)
  }, [handleKeyPress])

  useEffect(() => {
    if (bundleImagePackOptions?.selectedBundle) {
      switch (bundleImagePackOptions.selectedBundle) {
        case "duvet":
        case "duvetProduct":
          setIndex(bundleImagePackOptions.duvetIndex)
          break
        case "pillow":
        case "pillowProduct":
          setIndex(bundleImagePackOptions.pillowIndex)
          break
        case "sheet":
        case "flatSheetProduct":
          setIndex(bundleImagePackOptions.sheetIndex)
          break
        case "shortSleeve":
        case "shortSleeveProduct":
          setIndex(bundleImagePackOptions.shortSleeveIndex)
          break
        default:
          break
      }
    }
  }, [bundleImagePackOptions])

  return (
    <CarouselContainer
      imgIndex={hasHoverColor ? offset : imgIndex + offset}
      sx={sx}
      hasDarkBackground={images?.[imgIndex]?.hasDarkBackground}
      {...rest}
      {...swipeHandlers}
    >
      <CarouselButton
        data-ga-category="Carousel"
        data-ga-label="Previous Image"
        onClick={handlePrevious}
      />
      {images.map((i, index) => (
        <Image
          image={i.gatsbyImageData}
          src={i.src}
          className="prod-img"
          key={`carousel-image-${i.id || index}`}
          alt=""
          withZoom={withZoom}
          mobileWindowOpen={mobileWindowOpen}
          setMobileWindowOpen={setMobileWindowOpen}
        />
      ))}
      <CarouselButton
        next
        data-ga-category="Carousel"
        data-ga-label="Next Image"
        onClick={handleNext}
      />
      <Flex
        sx={{
          width: "100%",
          flexDirection: "column",
          position: "absolute",
          bottom: "0",
          left: "0",
          zIndex: 20,
        }}
        pb="10px"
        px="16px"
      >
        {textRender && <Box>{textRender()}</Box>}
        <Flex
          sx={{
            width: "100%",
            alignItems: "center",
          }}
          mt={"20px"}
        >
          <Flex sx={{ flex: "1" }}>
            <Text>{`${imgIndex + 1} of ${images?.length}`}</Text>
          </Flex>
          <Flex>
            <Arrow className="previous" onClick={handlePrevious} />
            <Arrow className="next" onClick={handleNext} />
          </Flex>
        </Flex>
      </Flex>
      {hoverImage && (
        <Flex
          sx={{ position: "absolute", height: "100%", width: "100%", left: 0 }}
        >
          <Image image={hoverImage.gatsbyImageData} alt="" />
        </Flex>
      )}
    </CarouselContainer>
  )
}
