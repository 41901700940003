import React from "react"
import styled from "@emotion/styled"

const Container = styled.div`
  display: flex;
  width: 40px;
  height: 24px;
  background-color: ${(props) => props.theme.colors.grayscale[400]};
  border-radius: 32px;
  align-items: center;
  padding-left: 1px;
  padding-right: 1px;
  transition: justify-content 400ms ease-in;
  cursor: pointer;

  ${(props) =>
    props.isOn &&
    `
      justify-content: flex-end;
      background-color: ${props.theme.colors.grayscale[700]};
    `}
`

const Circle = styled.div`
  background-color: ${(props) => props.theme.colors.grayscale.white};
  border-radius: 50%;
  width: 22px;
  height: 22px;
`

const Toggle = ({ isOn, onClick }) => {
  return (
    <Container isOn={isOn} onClick={onClick}>
      <Circle />
    </Container>
  )
}

export default Toggle
