import { useState } from "react"

const useMouseCoordinatesInElement = () => {
  const [coords, setCoords] = useState({ x: 0, y: 0, deltaX: 0, deltaY: 0 })

  const getDelta = (event) => {
    if (event) {
      var rect = event.target.getBoundingClientRect()
      const x = coords.x
      const y = coords.y

      let deltaX = (x - event.clientX) / 1
      let deltaY = (y - event.clientY) / 1

      // Check if the delta value is greated than a 6th of the rect width. Because then we are getting out of bounds
      if (Math.abs(deltaX) > rect.width / 6) {
        // Check positive or negative values
        if (deltaX < 0) {
          deltaX = -rect.width / 6
        } else {
          deltaX = rect.width / 6
        }
      }

      if (Math.abs(deltaY) > rect.height / 6) {
        // Check positive or negative values
        if (deltaY < 0) {
          deltaY = -rect.height / 6
        } else {
          deltaY = rect.height / 6
        }
      }

      setCoords((coords) => ({ ...coords, deltaX: deltaX, deltaY: deltaY }))
    }
  }

  return [coords, setCoords, getDelta]
}

export default useMouseCoordinatesInElement
